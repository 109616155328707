.about {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	padding: 20px;
	border-bottom: 1px solid #5d5a5a;
	justify-content: space-between;
	gap: 8px;

	.wrapTextContent {
		width: 50cqw;
		.wrapP {
			display: flex;
			flex-direction: column;
			gap: 10px;

			margin-top: 20px;

			.text {
			}
		}
	}
	.wrapImg {
		.imgGif {
			width: 100%;
			height: 400px; // Оставим ширину 100%, высота по контенту
			object-fit: cover;
			display: block; // Избегаем пробелов под изображением
		}
	}
}

@media (max-width: 768px) {
	.wrapTextContent,
	.wrapImg {
		flex: 1 1 100%; // На меньших экранах элементы займу всю ширину
	}
}
