.modal {
	position: absolute;
	display: flex;
	min-height: 40%;
	height: auto;
	width: 45%;

	z-index: 51;

	.main {
		height: 100%;
		width: 38%;

		background: rgba(0, 0, 0, 0.5);
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		border: 1px solid rgba(0, 0, 0, 0.25);
		.head {
			display: flex;
			align-items: center;
			height: 15%;
			width: 100%;
			padding: 5px;

			.line {
				position: absolute;
				left: 0;
				right: 0;
				top: 14%;
				width: 100%;
				height: 2px;
				background-color: rgba($color: #ffffff, $alpha: 0.1);
			}
			.title {
				height: 100%;
				width: 80%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
				gap: 2px;

				svg {
					height: 80%;
					width: 20%;
					cursor: pointer;
				}

				.like {
				}
			}
			.close {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 20%;
			}
		}
		.center {
			height: 85%;
			padding: 5px 5px 5px 10px;

			font-size: 18px;
			color: rgba($color: #ffffff, $alpha: 0.8);

			display: grid;
			gap: 5px 10px; /* Отступы между элементами */
			align-items: center; /* Выравнивание по вертикали */

			.wrapOptions {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-columns: 1fr max-content;
				:nth-child(2) {
					font-weight: bold;
					margin-right: 20px;
				}
			}
			.price {
				border-radius: 20px;
				padding: 0px 10px;
				background-color: rgba($color: #ffffff, $alpha: 1);
				color: #000;
			}
		}
		.footer {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 15%;

			button {
				height: 60%;
				width: 85%;
				background-color: #fff;
				border-radius: 20px;
				color: #000;
				text-transform: uppercase;
			}
		}
	}
	.extraBlock {
		height: auto;
		width: 62%;
		background: rgba(0, 0, 0, 0.5);
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		border: 1px solid rgba(0, 0, 0, 0.25);

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.headExtra {
			display: flex;
			align-items: center;
			height: 15%;
			width: 100%;
			padding: 5px;

			.line {
				position: absolute;
				left: 0;
				right: 0;
				top: 14%;
				width: 100%;
				height: 2px;
				background-color: rgba($color: #ffffff, $alpha: 0.1);
			}
			.title {
				height: 100%;
				width: 90%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
				gap: 2px;

				svg {
					height: 80%;
					width: 20%;
					cursor: pointer;
				}

				.like {
				}
			}
			.closeExtra {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 10%;
			}
		}
		.contentExtra {
			position: relative;
			height: 85%;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				pointer-events: none;
				height: 100%;
				width: 100%;
				object-fit: contain;
			}

			.btnZoom {
				position: absolute;
				top: 5%;
				right: 2%;
				height: 15%;
				aspect-ratio: 1 / 1;
				background-color: rgba($color: #000000, $alpha: 0.7);
				border-radius: 20px;
				color: #ffffff;
				text-transform: uppercase;

				padding: 10px;

				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background-color: rgba($color: #000000, $alpha: 0.9);
				}
				transition: all 0.2s ease-in-out;
			}
		}
	}
}

@media (768px <= width <= 991px) {
	.modal {
		width: 60%;
		.main {
			.center {
				font-size: 12px;
			}
		}

		.extraBlock {
			.contentExtra {
				.btnZoom {
					height: 20%;
					padding: 5px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.modal {
		width: 100%;
		flex-direction: column;
		.main {
			.center {
				font-size: 12px;
			}
		}
		.extraBlock {
			width: 100%;
			height: 45cqw;
		}
	}
}
