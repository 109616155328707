.header {
	width: 100%;
	height: 110px;
	padding: 5px 30px 5px 10px;
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;

	svg {
		height: 100%;
		width: fit-content;
		padding: 10px;
		g {
			path {
				fill: #fff;
			}
		}
	}

	transition: transform 0.3s ease-in-out;
	z-index: 2000;
}
.header--hidden {
	transform: translateY(-100%);
}
.header--visible {
	background: #000000;
	opacity: 0.7;
}
