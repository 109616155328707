:root {
	--primary-color: rgba(255, 255, 255, 1);
	--primary-hover-color: rgba(255, 255, 255, 0.8);
	--background-color: rgba(0, 0, 0, 0.5);
	--blur-filter: blur(10px);
	--border-color: rgba(255, 255, 255, 0.25);
	--text-color: #242424;
	--transition: all 0.3s ease;
}

.containerFilter {
	position: relative;
	height: auto;
}

.filter {
	position: absolute;
	width: 100%;
	height: 6%;
	bottom: 0;
	background: var(--background-color);
	backdrop-filter: var(--blur-filter);
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	text-transform: uppercase;
	font-size: 16px;
	z-index: 45;

	.filtersTab {
		padding: 12px 24px;
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		width: 18%;
		height: 100%;
		transition: var(--transition);

		button {
			color: var(--primary-color);
		}

		svg {
			min-width: fit-content;
		}

		&:hover {
			background: var(--primary-hover-color);
			color: var(--text-color);
		}

		&.active {
			background-color: var(--primary-hover-color);
			color: var(--text-color);

			&:hover {
				background: var(--primary-hover-color);
			}
		}

		&.disabled {
			opacity: 0.5;
			filter: grayscale(100%);
			pointer-events: none;
			cursor: not-allowed;
		}
	}

	.fullScreenTab {
		height: 100%;
		width: 10%;
		display: flex;
		align-items: center;
		justify-content: center;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			color: var(--primary-color);
			transition: var(--transition);

			&:hover {
				background: var(--primary-hover-color);
				color: var(--text-color);
			}
		}
	}
}

.closeExpand {
	position: absolute;
	bottom: 0;
	left: 18%;
	width: 50px;
	height: 6%;
	aspect-ratio: 1 / 1;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 46;
	cursor: pointer;
	background: var(--background-color);
	backdrop-filter: var(--blur-filter);
	transition: var(--transition);

	svg {
		width: 100%;
		height: 100%;
		padding: 5px;
		transition: var(--transition);

		&:hover {
			transform: scale(1.2);
		}
	}

	&.active {
		bottom: calc(24% + 6%);
	}

	.close {
		transform: rotate(180deg);

		&:hover {
			transform: scale(1.2) rotate(180deg);
		}
	}
}

@media (min-width: 1201px) {
	.closeExpand {
		&.active {
			bottom: calc(14% + 6%);
		}
	}

	.expandableBlock {
		height: 14%;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.filter {
		height: 8%;
		font-size: 18px;

		.filtersTab {
			padding: 16px 32px;
			gap: 12px;

			svg {
				width: 30px;
				height: 30px;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.6);
			}
		}

		.fullScreenTab {
			width: 120px;

			button {
				font-size: 20px;

				svg {
					width: 32px;
					height: 32px;
				}
			}
		}
	}

	.closeExpand {
		height: 8%;
		&.active {
			bottom: calc(30% + 8%);
		}
	}

	.expandableBlock {
		bottom: 8%;
		height: 30%;
		padding: 15px;
		gap: 10px;

		.rooms {
			.numberButtons {
				gap: 5px;

				button {
					font-size: 18px;
				}
			}
		}

		.sliders {
			.wrapRange {
				flex: 1 1 45%;
				min-width: 200px;

				.labelCost,
				.labelSurface {
					font-size: 18px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.filter {
		height: 10%;

		.filtersTab {
			p {
				display: none;
			}
		}
	}

	.closeExpand {
		height: 10%;

		&.active {
			bottom: calc(40% + 10%);
		}
	}

	.expandableBlock {
		height: 40%;
		bottom: 10%;
		overflow: auto;
	}
}

@media (max-width: 480px) {
	.filter {
		height: 12%;
	}

	.closeExpand {
		height: 12%;

		&.active {
			bottom: calc(60% + 12%);
		}
	}

	.expandableBlock {
		height: 88%;
		bottom: 12%;
		flex-direction: column;

		.rooms {
			width: 100%;
			align-items: center;

			.numberButtons {
				width: 100%;
				justify-content: space-between;

				button {
					flex: 1 1 1;
					width: 40px;
				}
			}
		}

		.sliders {
			width: 100%;
		}

		.reset {
			width: 100%;

			.wrapBtnReset {
				button {
					flex-grow: 1;
				}
			}
		}
	}
}
