.plane {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: auto;
	z-index: 1000;

	img {
		right: 0;
		width: 30cqw;
		height: auto;
		object-fit: cover;
	}
}
.svgContainer {
	svg {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&.active {
		svg {
			image {
				cursor: pointer;
				&:hover {
					filter: brightness(1.3);
				}
			}
		}
	}
}
.disable {
	filter: invert(1);

	&:hover {
		filter: invert(1);
		cursor: default;
	}
	cursor: not-allowed;
}
.invisible {
	display: none;
}
