:root {
	// Оранжевые переменные (для кнопок, фильтров и т.д.)
	--primary-color-orange: rgba(245, 101, 12, 1);
	--primary-hover-color-orange: rgba(245, 101, 12, 0.8);
	--background-color-dark: #262626;
	--text-color-dark: #000;
	--border-color-dark: #5d5a5a;
	--progress-color: #fff;
}

.demo {
	height: auto;
	border-bottom: 1px solid var(--border-color);
}

.wrapTitle,
.wrapSubTitle {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 10px;

	h2 {
		font-size: 2rem;
		text-transform: uppercase;
	}

	p {
		font-size: 1.2rem;
		text-align: center;
	}
}

.wrapBtn {
	background-color: var(--background-color-dark);
	padding: 10px 20px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 30px;

	p {
		padding: 20px;
	}

	a {
		height: fit-content;
	}

	button {
		padding: 10px 20px;
		cursor: pointer;
		background-color: var(--primary-color-orange);
		color: var(--text-color-dark);
		border-radius: 10px;
		font-weight: 700;

		&:hover {
			background-color: var(--primary-hover-color-orange);
		}
		transition: background-color 0.1s ease-in-out;
	}
}

.wrapLoader {
	width: 100%;
	height: auto;
	max-height: calc(100vh - 50px);
	aspect-ratio: 16/9;
	overflow: hidden;
	background-image: url('../../../public/Demo/loadImg.webp');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	display: flex;
	justify-content: center;
	align-items: center;

	progress {
		background-color: rgba(0, 0, 0, 1);
		--progress-color: var(--progress-color);
	}
}

.video-wrapper {
	position: relative;
	width: 100%;
	height: auto;
	max-height: calc(100vh - 50px);
	overflow: hidden;

	&.fullScreen {
		height: 100vh;
		max-height: 100vh;
	}

	video,
	.final-image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.final-image {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		&.visible {
			opacity: 1;
		}
	}

	.poligonContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		&.visible {
			pointer-events: all;
			opacity: 1;
			z-index: 40;
		}
	}

	.controls {
		width: 100%;
		height: 100%;

		&.hidden {
			.btn {
				z-index: 41;
			}
		}

		.btn {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			justify-content: center;

			color: #fff; // Белый цвет иконки
			border: none;
			opacity: 0.8;
			height: 100px;
			width: 60px; // Увеличиваем ширину для большего отступа
			cursor: pointer;
			z-index: 50;
			border-radius: 0 10px 10px 0; // Закругление с внутренней стороны

			&.back {
				left: 5%;
				background: linear-gradient(
					to right,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				); // Градиент для левой кнопки
				border-radius: 15px 0 0 15px; // Закругление с внутренней стороны

				img {
					rotate: 180deg;
				}
			}

			&.forward {
				right: 5%;
				background: linear-gradient(
					to left,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				); // Градиент для правой кнопки
				border-radius: 0 15px 15px 0; // Закругление с внутренней стороны
			}

			&:hover {
				opacity: 1;
			}

			transition: all 0.1s ease-in-out;
		}
	}
}

/* Адаптивные стили */
@media (min-aspect-ratio: 16/9) {
	.video-wrapper {
		height: 100vh;

		&.fullScreen {
			height: 100vh;
			max-height: 100vh;
		}
	}
}

@media (min-aspect-ratio: 1/1) {
	.video-wrapper {
		width: 100%;
		height: 100vh;

		&.fullScreen {
			height: 100vh;
			max-height: 100vh;
		}
	}
}

@media (max-width: 991px) {
	.video-wrapper {
		height: 100vh;
		&.fullScreen {
			height: 100vh;
			max-height: 100vh;
		}
	}
}

@media (max-width: 767px) {
	.video-wrapper {
		max-height: calc(100vh - 60px);

		&.fullScreen {
			height: 100vh;
			max-height: 100vh;
		}
	}

	.controls {
		display: none;

		.btn {
			height: 60px;
			width: 40px;
		}
	}
}
