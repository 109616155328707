.portfolioTitle {
	font-size: 2rem;
	text-transform: uppercase;
}
.portfolioContent {
	display: flex;
	height: auto;
	.portfolioImg {
		height: 50%;
		width: auto;
		img {
			aspect-ratio: 1 / 1;
		}
	}
}
.architectureImage {
	overflow: hidden;
	&:hover {
		filter: brightness(0.6);
	}

	transition: all 0.1s ease-in-out;
}
