.expandableBlock {
	position: absolute;
	bottom: 6%;
	width: 100%;
	height: 24%;
	display: flex;
	gap: 20px;
	padding: 10px;
	background: var(--background-color);
	backdrop-filter: var(--blur-filter);
	border-bottom: 1px solid var(--border-color);
	z-index: 44;
	transition: var(--transition);

	&.hidden {
		pointer-events: none;
		bottom: 0;
		opacity: 0;
	}

	.rooms {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 12.5%;
		gap: 5px;

		.numberButtons {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 5px;

			button {
				width: 25px;
				height: auto;
				aspect-ratio: 1 / 1;
				background: var(--primary-color);
				border: none;
				border-radius: 5px;
				padding: 2px;
				cursor: pointer;
				color: var(--text-color);
				font-size: 16px;
				transition: background 0.3s;

				svg {
					width: 100%;
					height: 100%;
				}

				&:hover {
					background: var(--primary-hover-color);
				}

				&.active {
					background-color: rgba(255, 255, 255, 0.6);
					box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px 0px inset;
				}
			}
		}
	}

	.sliders {
		width: 75%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 20px;

		.wrapRange {
			flex: 1 1 30%;
			min-width: 250px;
			padding: 0px 24px;

			.label {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 8px;
			}
		}
	}

	.reset {
		width: 12.5%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 10px;

		.wrapBtnReset {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 5px;

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				background: var(--primary-color);
				border: none;
				border-radius: 8px;
				padding: 5px 10px;
				cursor: pointer;
				color: var(--text-color);
				transition: background 0.3s;

				&:hover {
					background: var(--primary-hover-color);
				}
			}
		}
	}
}

@media (min-width: 1201px) {
	.expandableBlock {
		height: 14%;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.expandableBlock {
		bottom: 8%;
		height: 30%;
		padding: 15px;
		gap: 10px;

		.rooms {
			.numberButtons {
				gap: 5px;

				button {
					font-size: 18px;
				}
			}
		}

		.sliders {
			.wrapRange {
				flex: 1 1 45%;
				min-width: 200px;

				.labelCost,
				.labelSurface {
					font-size: 18px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.expandableBlock {
		height: 40%;
		bottom: 10%;
		overflow: auto;
	}
}

@media (max-width: 480px) {
	.expandableBlock {
		height: 60%;
		bottom: 12%;
		flex-direction: column;

		.rooms {
			width: 100%;
			align-items: center;

			.numberButtons {
				width: 100%;
				justify-content: space-between;

				button {
					flex: 1 1 1;
					width: 40px;
				}
			}
		}

		.sliders {
			width: 100%;
		}

		.reset {
			width: 100%;

			.wrapBtnReset {
				button {
					flex-grow: 1;
				}
			}
		}
	}
}
