@tailwind base;
@tailwind components;
@tailwind utilities;

/* Обнуление отступов */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Наследование шрифта для всех элементов */
html {
	font-family: sans-serif;
	line-height: 1.5;
	-webkit-text-size-adjust: 100%; /* Корректировка размера текста на устройствах */
	-webkit-tap-highlight-color: transparent; /* Отключение выделения при нажатии на мобильных устройствах */
	scroll-behavior: smooth;
}

/* Обнуление базовых тегов для совместимости */
body {
	margin: 0;
	padding: 0;
}

/* Убираем подчеркивание у ссылок по умолчанию */
a {
	text-decoration: none;
	color: inherit;
}

/* Обнуляем списки */
ul,
ol {
	list-style: none;
}

/* Обнуление стилей изображений */
img {
	max-width: 100%;
	height: auto;
	border: 0;
	display: block;
}

/* Обнуление таблиц */
table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

/* Убираем рамки у полей формы */
input,
button,
textarea,
select {
	font: inherit;
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	box-shadow: none;
	appearance: none;
	outline: none;
}

/* Глобальное выравнивание и обнуление кнопок */
button {
	background-color: transparent;
	cursor: pointer;
	padding: 0;
	border: none;
}

/* Обнуляем поля ввода */
input,
textarea {
	border-radius: 0;
	resize: none;
}

/* Убираем тень у полей ввода в фокусе для iOS */
input[type='text'],
input[type='search'] {
	-webkit-appearance: none;
}

/* Сброс заголовков */
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
	font-size: inherit;
}

/* Глобальное обнуление границ */
fieldset {
	border: 0;
}

.navMenu {
	width: max-content;
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin-right: 5vw;
}

.mail,
.tel,
.formLink {
	display: flex;
	cursor: pointer;
	color: #ffffff;
	text-decoration: none;
	font-size: 12px;
	opacity: 0.7;
	padding: 5px 8px;
	background: #232323;
	border-radius: 5px;
	text-align: center;
	align-items: center;

	&:hover {
		background: #f5650c;
		color: #000000;
		opacity: 1;
		box-shadow: 0 4px 10px rgba(245, 101, 12, 0.5);
		transform: translateY(-2px);
	}

	transition: all 0.3s ease;
}

.about,
.offer,
.video,
.form {
	display: flex;
	padding: 20px;
	border-bottom: 1px solid #5d5a5a;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 8px;
}

.form {
	justify-content: center;
}

.formBlock {
	width: 45%;
	height: 200px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	border-radius: 8px;
	background: rgb(45, 45, 45);
	padding: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	transition: box-shadow 0.3s ease;
	cursor: pointer;

	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
	}
}

.formBlock {
	background: rgb(38, 38, 38);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
	width: 100%;
	height: max-content;
	gap: 20px;
	padding: 20px;
}

.valueForm {
	border-radius: 4px;
	background: rgb(45, 45, 45);
	border-color: rgba(0, 0, 0, 0.7);
	width: 80%;
	color: #ffffff;
	height: 20px;

	&:focus {
		border-color: rgba(0, 0, 0, 0.9);
	}

	&:active {
		border-color: rgba(0, 0, 0, 0.9);
	}
}

.textareaValue {
	width: 95%;
	border-radius: 4px;
	background: rgb(45, 45, 45);
	border-color: rgba(0, 0, 0, 0.7);
	color: #ffffff;
	min-height: 200px;
}

.commercialOffer,
.vedeoBlock {
	display: flex;
	width: 40%;
	flex-direction: column;
	justify-content: flex-start;
	min-width: 270px;
	height: auto;
	position: relative;
}

.aboutUseTitle,
.commercialOfferTitle,
.videoTitle {
	text-transform: uppercase;
	font-size: 2rem;
	display: flex;
}

.aboutUseText,
.commercialOfferText,
.portfolioText,
.videoText {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.portfolioTitle,
.portfolioText {
	margin-left: 70px;
}

.text {
	font-size: 1rem;
	display: flex;
}

.commercialOfferImg,
.portfolioImg {
	width: auto;
	height: 400px;
	position: relative;
	min-width: 270px;
}

.commercialOfferBanner,
.portfolioBanner {
	width: 100%;
	height: 400px;
	object-fit: cover;
}

.commercialOfferBanner {
	max-width: 500px;
}

.offer,
.tasks {
	background: rgb(38, 38, 38);
}

ul li::marker {
	color: rgb(245, 101, 12);
}

@media screen and (max-width: 991px) {
	.offer {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.commercialOfferImg {
		width: 90%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.commercialOffer {
		width: 90%;
	}
}
