.banner {
	width: 100%;
	height: 70vh;
	overflow: hidden;
	background-size: cover;
	object-fit: cover;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 70vh;
		background-color: rgba(0, 0, 0, 0.5); /* Цвет и прозрачность затемнения */
	}
}

.bannerImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top right;
	transform: scale(-1, 1);
}

.bannerTitle {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 25vh;
	left: 0;
	z-index: 1000;
	text-align: left;
	padding: 20px;
	font-size: 3rem;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;

	span {
		display: inline-block;
		font-size: 1.2rem;
		font-weight: normal;
		text-transform: none;
	}
}
