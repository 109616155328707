.tasks {
	display: grid;
	gap: 16px;
	padding: 16px;
	grid-template-columns: repeat(2, 1fr);
	.taskBlock {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-direction: column;
		border-radius: 8px;
		background: rgb(45, 45, 45);
		padding: 20px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
		transition: box-shadow 0.3s ease;
		// cursor: pointer;

		&:hover {
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
		}
		.taskTitle {
			color: rgb(245, 101, 12);
			font-weight: bold;
			display: flex;
			justify-content: center;
			text-transform: uppercase;
		}
		.dummyDesc {
			display: flex;
			justify-content: center;
			text-align: center;
		}
	}
}
.wrap {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 20px;

	@media (max-width: 768px) {
		flex-direction: column;
	}

	.imgWrap {
		flex: 1;
		max-width: 50%; // Ограничение по ширине изображения
		img {
			width: 100%;
			height: auto;
			object-fit: contain; // Сохраняет пропорции изображения
			border-radius: 8px;
			max-height: 400px; // Ограничение по высоте изображения для предотвращения скролла
		}
		// Для мобильных устройств изображение занимает всю ширину
		@media (max-width: 768px) {
			max-width: 100%;
			max-height: 300px; // Уменьшаем высоту на мобильных
		}
	}

	.deskWrap {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 10px;

		p {
			font-size: 1.1rem;
			line-height: 1.6;
			color: #ffffff;
		}
	}
}

@media (min-width: 1024px) {
	.tasks {
		grid-template-columns: repeat(
			4,
			1fr
		); /* Четыре колонки на больших экранах */
	}
}

@media (max-width: 768px) {
	.tasks {
		grid-template-columns: repeat(2, 1fr); /* Две колонки на средних экранах */
		gap: 12px;
	}

	.taskBlock {
		padding: 16px;
	}

	.taskTitle {
		font-size: 1.15rem;
	}

	.dummyDesc {
		font-size: 0.95rem;
	}
}

@media (max-width: 480px) {
	.tasks {
		grid-template-columns: 1fr; /* Одна колонка на маленьких экранах */
		gap: 10px;
	}

	.taskBlock {
		padding: 12px;
	}

	.taskTitle {
		font-size: 1rem;
	}

	.dummyDesc {
		font-size: 0.9rem;
	}
}
